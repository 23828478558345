import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout/Layout';

export type Props = {
  data?: any;
};
const NotFoundPage = ({ data: { allContentfulImages } }: Props) => {
  const logo = allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    allContentfulImages.edges[0].node.backgrounds[0].file.url;
  return (
    <Layout
      title="404"
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      description="Przepraszamy, ta strona nie istnieje"
      ogTitle="404 | Smaki Azji"
      ogContent="Przykro nam, strona której szukasz nie istnieje"
    >
      <h1>Przykro nam, ale strona którą próbujesz znaleźć nie istnieje</h1>
    </Layout>
  );
};

export const getImages = graphql`
  query {
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
